import Image from 'next/image';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import Button from 'components/Button';
import classNames from 'styles/utils/classNames';
import { CoachPromptProps } from '../props';

const CoachPrompt: React.FC<CoachPromptProps> = ({
  visible,
  onChangeStep,
  selectedPlayer,
  handleClose,
}) => {
  return (
    <div className={classNames('hidden flex-col', visible && '!flex')}>
      <div className="flex flex-col items-center justify-center px-8 text-center">
        <img
          alt="profile-avatar"
          src={selectedPlayer?.followerProfile?.profileImageProviderUrl || EMPTY_AVATAR_SRC}
          className="h-32 w-32 rounded-full"
        />

        <h2 className="typography-product-heading-desktop mt-8">
          Reflect on your lesson with {selectedPlayer?.followerProfile?.fullName}
        </h2>
        <p className="typography-product-body mt-2 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Take a couple of minutes to give Loren feedback on their performance.
        </p>
      </div>
      <div className="mt-12 flex gap-4 border-t px-8 py-4">
        <Button variant="secondary" size="lg" onClick={handleClose}>
          Remind me later
        </Button>
        <Button variant="brand" size="lg" onClick={() => onChangeStep(1)}>
          Start Reflection
        </Button>
      </div>
    </div>
  );
};

export default CoachPrompt;
