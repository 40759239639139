import CloseIcon from 'svg/CloseIcon';
import { ButtonText } from 'components/Button';
import { ReflectionModalHeaderProps } from './props';

const ModalHeader: React.FC<ReflectionModalHeaderProps> = ({ title, handleClose }) => {
  return (
    <div className="flex justify-between px-8 py-6">
      <h4 className="typography-product-heading-desktop">{title}</h4>
      <ButtonText onClick={handleClose}>
        <CloseIcon className="h-5 w-5" />
      </ButtonText>
    </div>
  );
};

export default ModalHeader;
