import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import CheckIcon from 'svg/CheckIcon';
import ChevronDown from 'svg/ChevronDown';
import { getSkillLabel } from 'components/SkillAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { NumberedDropdownProps } from './props';

const NumberedDropdown: React.FC<NumberedDropdownProps> = ({
  num,
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <div className="flex items-center gap-4">
      <span className="typography-product-heading-mobile w-5">{num}</span>
      <Listbox value={selectedValue} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="relative w-full">
              <Listbox.Button
                className={
                  'input-form relative flex min-h-10 w-full items-center justify-between text-left'
                }
              >
                <span
                  className={classNames(
                    'typography-product-caption block',
                    selectedValue
                      ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
                      : 'text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder',
                  )}
                >
                  {selectedValue ? getSkillLabel(selectedValue) : 'Select Skill'}
                </span>
                <ChevronDown
                  className={classNames(
                    'h-5 w-5 transition-transform duration-300',
                    open && 'rotate-180',
                  )}
                />
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-color-bg-lightmode-primary py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-color-bg-darkmode-primary sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'bg-color-bg-lightmode-invert text-color-text-lightmode-invert'
                            : 'text-gray-900',
                          'relative cursor-pointer select-none px-4 py-2',
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={'block pr-2'}>{getSkillLabel(option)}</span>

                          {selected ? (
                            <span
                              className={classNames(
                                active
                                  ? 'text-color-text-lightmode-invert'
                                  : 'text-color-text-darkmode-invert',
                                'absolute inset-y-0 right-0 flex items-center pr-1',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default NumberedDropdown;
