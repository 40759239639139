import React, { useCallback, useRef, useState } from 'react';
import { StopIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import { captureException } from '@sentry/nextjs';
import { buttonVariants } from 'components/Button/Button';

interface RecordVideoProps {
  onRecordingComplete: (blob: Blob, fileType: string) => void;
  isUploading: boolean;
  showPreview?: boolean;
}

export default function RecordVideo({
  onRecordingComplete,
  isUploading,
  showPreview = true,
}: RecordVideoProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const isDisabled = isUploading;
  const [error, setError] = useState<string | null>(null);

  const selectVideoFormat = useCallback(() => {
    const formats = [
      { mimeType: 'video/mp4', description: 'MP4', fileType: 'mp4' },
      {
        mimeType: 'video/webm;codecs=vp9,opus',
        description: 'WebM with VP9 and Opus codecs',
        fileType: 'webm',
      },
      {
        mimeType: 'video/webm;codecs=vp8,opus',
        description: 'WebM with VP8 and Opus codecs',
        fileType: 'webm',
      },
    ];

    for (let format of formats) {
      if (MediaRecorder.isTypeSupported(format.mimeType)) {
        console.log(`Selected format: ${format.description}`);
        return format;
      }
    }

    captureException(new Error('No common video formats supported by MediaRecorder.'));
    return null;
  }, []);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      streamRef.current = stream;
      const format = selectVideoFormat();

      if (!format?.mimeType) {
        throw new Error('No supported video format found.');
      }

      // Display live preview
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      }

      const mediaRecorder = new MediaRecorder(stream, { mimeType: format.mimeType });
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: format.mimeType });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
        onRecordingComplete(blob, format.fileType);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setError(null); // Clear any previous errors
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
      setError(
        'Camera and microphone access are required to record video. Please allow access and try again.',
      );
    }
  }, [onRecordingComplete, selectVideoFormat]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setRecordingTime(0);

      // Stop all tracks in the stream to release the camera and microphone
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Clear the MediaRecorder reference
      mediaRecorderRef.current = null;
      streamRef.current = null;

      // Stop live preview
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  }, []);

  return (
    <div className="w-full">
      {error && (
        <div className="mb-2 text-pretty text-xs font-medium text-color-error">{error}</div>
      )}
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className="relative flex w-full items-center overflow-hidden rounded-lg border border-color-border-input-lightmode px-2 py-2 dark:border-color-border-input-darkmode"
        type="button"
        disabled={isDisabled}
      >
        {isRecording && (
          <div className="absolute inset-0 animate-recording-background bg-gradient-to-r from-transparent via-brand-fire-200 to-transparent dark:via-gray-700" />
        )}
        <span
          className={buttonVariants({
            variant: isRecording ? 'primary' : 'brand',
            size: 'icon',
            className: 'relative z-10 mr-2 w-auto p-2.5',
            inline: true,
            inlineDesktop: true,
          })}
        >
          {isRecording ? <StopIcon className="h-4 w-4" /> : <VideoCameraIcon className="h-4 w-4" />}
        </span>
        <div className="relative z-10 text-sm font-semibold">
          {!isRecording && <span>Record video</span>}
          {isRecording && (
            <span>
              Recording:{' '}
              <span className="font-bold text-color-brand-primary">{recordingTime} seconds</span>
            </span>
          )}
        </div>
      </button>
      <div className="mt-2">
        <video
          ref={videoRef}
          className="w-full rounded-lg"
          muted
          playsInline
          style={{ display: isRecording ? 'block' : 'none' }}
        />
        {showPreview && videoUrl && !isRecording && (
          <video src={videoUrl} controls className="w-full rounded-lg" />
        )}
      </div>
    </div>
  );
}
