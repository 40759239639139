import { NEW_LESSON_PAGE } from 'constants/pages';
import AddUserIcon from 'svg/AddUserIcon';
import FilterIcon from 'svg/FilterIcon';
import PlusPlain from 'svg/PlusPlain';
import { ButtonLink, ButtonText } from 'components/Button';
import { Button } from 'components/Button';
import classNames from 'styles/utils/classNames';

const Header: React.FC<{
  openFilterModal: () => void;
  playersCount: number;
  handleInvitePlayers: () => void;
}> = ({ openFilterModal, playersCount, handleInvitePlayers }) => {
  const inviteMobileBtnShadowClass = 'shadow-[0px_4px_20px_0px_rgba(0,0,0,0.16)]';

  return (
    <div className="items-between flex justify-between">
      <div className="flex items-center ">
        <h3 className="typography-product-heading-compact-desktop">Clients {playersCount}</h3>
        <div className="flex items-center gap-2">
          <ButtonText className="hidden items-center gap-1" onClick={openFilterModal}>
            <FilterIcon className="h-5 w-5" /> Filters
          </ButtonText>
        </div>
      </div>
      <div className={classNames('flex gap-4', 'md:hidden')}>
        <Button
          variant="secondary"
          sizeDesktop="lg"
          sizeMobile="sm"
          onClick={handleInvitePlayers}
          className="max-sm:hidden"
          iconLeft={<AddUserIcon className="h-5 w-5" />}
        >
          Invite Clients
        </Button>
        <ButtonText
          onClick={handleInvitePlayers}
          className={classNames(
            'shrink-0 rounded-full bg-color-bg-lightmode-primary p-2 dark:bg-color-bg-darkmode-primary sm:hidden',
            inviteMobileBtnShadowClass,
          )}
        >
          <AddUserIcon className="h-5 w-5" />
        </ButtonText>
        <ButtonLink
          sizeDesktop="lg"
          sizeMobile="sm"
          variant="brand"
          iconLeft={<PlusPlain className="h-5 w-5" />}
          className="whitespace-nowrap"
          href={NEW_LESSON_PAGE}
        >
          Create Lesson
        </ButtonLink>
      </div>
    </div>
  );
};

export default Header;
