import { z } from 'zod';
import { FeedbackSentimentEnum } from 'types/generated/client';
import { ReflectionFormType } from './props';

const reflectionFormSchema = z
  .object({
    skills: z.array(z.string()).optional(),
    customSkills: z.array(z.string()).optional(),
    skillsRanking: z.array(z.string()),
    feedback: z
      .array(
        z.object({
          sentiment: z.enum([
            FeedbackSentimentEnum.Positive,
            FeedbackSentimentEnum.Neutral,
            FeedbackSentimentEnum.Critical,
          ]),
          skill: z.string().optional(),
          customSkill: z.string().optional(),
          details: z.string().optional(), // Detailed feedback for each skill
          lessonReflectionFiles: z.any().optional(), // Media upload for each feedback entry
        }),
        // .refine((data) => data.details || data.lessonReflectionFiles, {
        //   message: 'Please provide either details or upload media.',
        //   path: ['details'], // Error message under 'details'
        // })
        // .refine((data) => data.details || data.lessonReflectionFiles, {
        //   message: 'Please provide either details or upload media.',
        //   path: ['lessonReflectionFiles'], // Error message under 'lessonReflectionFiles'
        // }),
      )
      .min(1, 'You must provide feedback for at least one skill'),
  })
  .refine(
    (data) => {
      const { skills, skillsRanking } = data;

      const duplicates = skillsRanking.filter(
        (item, index) => skillsRanking.indexOf(item) !== index,
      );
      if (duplicates.length > 0) {
        return false;
      }
      return true;
    },
    {
      message: 'Cannot rank the same skill multiple times.',
      path: ['skillsRanking'],
    },
  )
  .refine(
    (data) => {
      const { skills, skillsRanking, customSkills } = data;
      return skillsRanking.length === (skills?.length || 0) + (customSkills?.length || 0);
    },
    {
      message: 'Cannot leave a skill empty; rank all selected skills.',
      path: ['skillsRanking'],
    },
  );

export { reflectionFormSchema };

export const reflecionFormDefaultValues: ReflectionFormType = {
  skills: [],
  skillsRanking: [],
  feedback: [],
};
