import React, { useMemo } from 'react';
import { FeedbackSentimentEnum } from 'types/generated/client';
import classNames from 'styles/utils/classNames';
import { FeedbackOptionProps } from '../../screens/VideoAnalysis/props';

const FeedbackOption: React.FC<FeedbackOptionProps> = ({
  title,
  selected = false,
  type,
  onClick,
}) => {
  const className = useMemo(() => {
    const defaultClass =
      'bg-color-bg-lightmode-secondary dark:bg-color-bg-darkmode-secondary text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary';

    if (selected) {
      switch (type) {
        case FeedbackSentimentEnum.Positive:
          return 'bg-green-200';
        case FeedbackSentimentEnum.Critical:
          return 'bg-red-200';
        case FeedbackSentimentEnum.Neutral:
          return 'bg-yellow-200';
        default:
          return defaultClass;
      }
    }
    return defaultClass;
  }, [type, selected]);

  return (
    <div
      onClick={() => onClick(type)}
      className={classNames(
        'grid h-8 flex-1 cursor-pointer items-center rounded-md text-center',
        className,
      )}
    >
      <span className="typography-product-button-label-medium text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {title}
      </span>
    </div>
  );
};

export default FeedbackOption;
