import { NEW_LESSON_PAGE } from 'constants/pages';
import AddUserIcon from 'svg/AddUserIcon';
import PlusPlain from 'svg/PlusPlain';
import Button, { ButtonLink, ButtonText } from 'components/Button';
import classNames from 'styles/utils/classNames';

const CoachPlayerTabHeader: React.FC<{ handleInvitePlayers: () => void }> = ({
  handleInvitePlayers,
}) => {
  const inviteMobileBtnShadowClass = 'shadow-[0px_4px_20px_0px_rgba(0,0,0,0.16)]';

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-1 border-b p-ds-xl max-sm:px-4',
        'max-md:hidden',
      )}
    >
      <h2 className="typography-product-heading-desktop">Clients</h2>
      <div className="flex gap-4">
        <Button
          variant="secondary"
          sizeDesktop="lg"
          sizeMobile="sm"
          onClick={handleInvitePlayers}
          className="max-sm:hidden"
          iconLeft={<AddUserIcon className="h-5 w-5" />}
        >
          Invite Clients
        </Button>
        <ButtonText
          onClick={handleInvitePlayers}
          className={classNames(
            'shrink-0 rounded-full bg-color-bg-lightmode-primary p-2 dark:bg-color-bg-darkmode-primary sm:hidden',
            inviteMobileBtnShadowClass,
          )}
        >
          <AddUserIcon className="h-5 w-5" />
        </ButtonText>
        <ButtonLink
          sizeDesktop="lg"
          sizeMobile="sm"
          variant="brand"
          iconLeft={<PlusPlain className="h-5 w-5" />}
          className="whitespace-nowrap"
          href={NEW_LESSON_PAGE}
        >
          Create Lesson
        </ButtonLink>
      </div>
    </div>
  );
};

export default CoachPlayerTabHeader;
