import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FeedbackSentimentEnum, LessonSkillsEnum } from 'types/generated/client';
import Stepper from 'screens/TournamentsBuilder/components/Stepper';
import Modal from 'components/modals/Modal';
import { Step1, Step2 } from './CoachSteps';
import PlayerAvatar from './PlayerAvatar';
import ModalHeader from './ReflectionModalHeader';
import { ReflectionFlowModalProps, ReflectionFormType } from './props';
import { reflecionFormDefaultValues, reflectionFormSchema } from './utils';

const ReflectionFlowModal: React.FC<ReflectionFlowModalProps> = ({
  isOpen,
  handleClose,
  selectedPlayerForReflection,
  handleAddReflection,
  submitting,
  initialValues,
  handleEditReflection,
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(1);

  const reflectionForm = useForm<ReflectionFormType>({
    resolver: zodResolver(reflectionFormSchema),
    defaultValues: reflecionFormDefaultValues,
  });

  const isEditMode = !!initialValues;

  const [selectedSkills, rankedSkills, skillsFeedback] = reflectionForm.watch([
    'skills',
    'skillsRanking',
    'feedback',
  ]);

  const handleChangeStep = async (stepIndex: number) => {
    if (stepIndex === 3) {
      try {
        const values = reflectionForm.getValues();

        if (isEditMode) {
          console.log('Attempting Edit');
          await handleEditReflection(values);
        } else {
          console.log('Attempting Add');
          await handleAddReflection(values);
        }
        closeModal();
      } catch (err) {
        console.error('Step Change Error:', err);
      }
      return;
    }
    setActiveStepIndex(stepIndex);
  };

  const closeModal = () => {
    handleClose();
    setTimeout(() => {
      setActiveStepIndex(1);
      reflectionForm.reset(reflecionFormDefaultValues);
    }, 300); // for closing animation
  };

  useEffect(() => {
    const skillsFeedback = rankedSkills.map((skill) => {
      const initialSkillFeedback = initialValues
        ? initialValues?.feedback.find(({ skill: initialSkill }) => initialSkill === skill)
        : null;

      const isCustomSkill = !Object.values(LessonSkillsEnum).includes(skill as LessonSkillsEnum);

      return {
        skill: !isCustomSkill ? skill : undefined,
        customSkill: isCustomSkill ? skill : undefined,
        sentiment: initialSkillFeedback?.sentiment || FeedbackSentimentEnum.Positive,
        lessonReflectionFiles: initialSkillFeedback?.lessonReflectionFiles || null,
        details: initialSkillFeedback?.details || '',
      };
    });

    reflectionForm.setValue('feedback', skillsFeedback);
  }, [rankedSkills, reflectionForm.setValue, initialValues]);

  useEffect(() => {
    const currentSkillsRanking = [...rankedSkills];

    const filteredRanking = currentSkillsRanking.filter((skill) => selectedSkills?.includes(skill));

    if (filteredRanking.length !== currentSkillsRanking.length) {
      reflectionForm.setValue('skillsRanking', filteredRanking);
    }
  }, [selectedSkills, reflectionForm.setValue]);

  useEffect(() => {
    if (initialValues) {
      reflectionForm.reset(initialValues);
    }
  }, [initialValues]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={closeModal}
      classNameMaxWidth="max-w-[40rem] overflow-visible"
    >
      <ModalHeader
        title={isEditMode ? 'Edit Reflection' : 'Add Reflection'}
        handleClose={closeModal}
      />
      {activeStepIndex > 0 && (
        <div className="px-8">
          <Stepper
            className="max-w-full"
            currentStep={activeStepIndex - 1}
            steps={[
              { title: '', description: '' },
              { title: '', description: '' },
            ]}
          />

          <PlayerAvatar selectedPlayer={selectedPlayerForReflection} />
          <hr className="my-8" />
        </div>
      )}
      {/* can be used later  */}
      {/* <CoachPrompt
        selectedPlayer={selectedPlayerForReflection}
        visible={activeStepIndex === 0}
        onChangeStep={handleChangeStep}
        handleClose={handleClose}
      /> */}
      <Step1
        visible={activeStepIndex === 1}
        control={reflectionForm.control}
        selectedSkills={selectedSkills || []}
        trigger={reflectionForm.trigger}
        onChangeStep={handleChangeStep}
        getValues={reflectionForm.getValues}
        setValue={reflectionForm.setValue}
      />
      <Step2
        visible={activeStepIndex === 2}
        control={reflectionForm.control}
        skillsFeedback={skillsFeedback || []}
        trigger={reflectionForm.trigger}
        onChangeStep={handleChangeStep}
        clearErrors={reflectionForm.clearErrors}
        setError={reflectionForm.setError}
        submitting={submitting}
        errors={reflectionForm.formState.errors}
      />
    </Modal>
  );
};

export default ReflectionFlowModal;
