import Image from 'next/image';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import Calendar from 'svg/Calendar';
import { PlayerType } from 'screens/CoachPlayersTab/props';
import { formatDate } from 'screens/TournamentDetails/utils';

const PlayerAvatar: React.FC<{ selectedPlayer: PlayerType | null }> = ({ selectedPlayer }) => {
  return (
    <div className="mt-8 flex gap-3">
      <img
        alt="profile-avatar"
        src={selectedPlayer?.followerProfile?.profileImageProviderUrl || EMPTY_AVATAR_SRC}
        className="h-12 w-12  rounded-full"
      />

      <div className="flex flex-col gap-1">
        <h5 className="typography-product-subheading capitalize">
          {selectedPlayer?.followerProfile?.fullName}
        </h5>
        {selectedPlayer?.pendingAction?.lessonDate && (
          <span className="typography-product-text-card flex gap-1">
            <Calendar className="h-4 w-4 text-color-text-lightmode-icon dark:text-color-text-darkmode-icon" />
            {formatDate({ date: selectedPlayer?.pendingAction?.lessonDate })}
          </span>
        )}
      </div>
    </div>
  );
};

export default PlayerAvatar;
