import { useMemo } from 'react';
import { AccountType } from 'constants/user';
import { VideoAnalysis } from 'types/generated/client';
import CustomTable from 'components/CustomTable';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import ReflectionSection from 'components/SkillAnalysis/ReflectionSection';
import { Tabs } from 'components/SkillAnalysis/enums';
import { processLessonReflections } from 'components/SkillAnalysis/utils';
import OnCourtStats from './PlayerDetailsSection/OnCourt/Stats';
import RemoteStats from './PlayerDetailsSection/Remote/Stats';
import VideoAnalysisTable from './PlayerDetailsSection/Remote/VideoAnalysisTable';
import { AllPlayerSectionProps } from '../props';

const AllPlayerSection: React.FC<AllPlayerSectionProps> = ({
  data,
  activeTab,
  handleReflectOnPlayer,
  handleTriggerEditReflection,
}) => {
  const totalVideosCount = data?.remote?.videoAnalysisAggregate.aggregate?.count || 0;

  const pendingVideosCount = data?.remote?.pendingVideoAnalysisCount.aggregate?.count || 0;

  const videos = data?.remote?.videoAnalysis || [];

  const lessonTakenCount = data?.onCourt?.lessons.length || 0;

  const processedReflections = useMemo(
    () => (data?.onCourt?.lessons ? processLessonReflections(data.onCourt?.lessons, true) : []),
    [data?.onCourt?.lessons],
  );

  const awaitingAnalysis = useMemo(
    () => processedReflections.filter((reflection) => reflection.isCoachReflectionPending).length,
    [processedReflections],
  );

  return (
    <div className="mt-11 flex flex-col gap-11">
      {activeTab === Tabs.Remote && (
        <>
          <RemoteStats videosCount={totalVideosCount} pendingCount={pendingVideosCount} />
          <VideoAnalysisTable isAllPlayersInfo data={videos as VideoAnalysis[]} />
        </>
      )}
      {activeTab === Tabs.OnCourt && (
        <>
          <OnCourtStats lessonTakenCount={lessonTakenCount} awaitingAnalysis={awaitingAnalysis} />
          {processedReflections.length > 0 ? (
            <>
              <ReflectionSection
                userType={AccountType.Coach}
                onReflect={handleReflectOnPlayer}
                onEditReflection={handleTriggerEditReflection}
                reflections={processedReflections}
                table
                isAllPlayersInfo
                showDivider={false}
              />
            </>
          ) : (
            <NoDataPlaceholder text="No Remaining Reflections" className="mt-11" />
          )}
        </>
      )}
    </div>
  );
};

export default AllPlayerSection;
