import { useMemo } from 'react';
import Image from 'next/image';
import { getVideoAnalysisPageUrl } from 'constants/pages';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import { isUuidFormatValid } from 'utils/shared/string/isUuidFormatValid';
import NoteIcon from 'svg/NoteIcon';
import VideoCamera from 'svg/VideoCamera';
import { PlayerCardProps } from 'screens/CoachPlayersTab/props';
import Button, { ButtonLink } from 'components/Button';
import classNames from 'styles/utils/classNames';

const PlayerCard: React.FC<PlayerCardProps> = ({
  selected = false,
  player,
  handlePlayerSelect,
  handleSelectAll,
  all = false,
}) => {
  const activeCardShadowClass =
    'shadow-[0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#B9B9BE,0px_4px_20px_0px_rgba(0,0,0,0.16)]';

  if (all) {
    return (
      <div
        onClick={() => handleSelectAll?.()}
        className={classNames(
          'flex min-w-0 cursor-pointer items-center rounded-lg bg-color-bg-lightmode-primary  p-ds-lg transition dark:bg-color-bg-darkmode-primary',
          selected && activeCardShadowClass,
        )}
      >
        <span className="typography-product-element-label">All Clients</span>
      </div>
    );
  }

  if (!player) return;

  const isUsernameValid = useMemo(
    () => isUuidFormatValid(player.followerProfile?.username || '') === false,
    [player?.followerProfile?.username],
  );

  return (
    <div
      onClick={() => handlePlayerSelect?.(player, 'detail')}
      className={classNames(
        'flex min-w-0 cursor-pointer items-center gap-4 rounded-lg bg-color-bg-lightmode-primary  p-ds-sm transition dark:bg-color-bg-darkmode-primary',
        selected && activeCardShadowClass,
      )}
    >
      <div className="relative h-14 w-14 shrink-0 overflow-hidden rounded-full">
        <Image
          alt="profile-avatar"
          fill
          src={player?.followerProfile?.profileImageProviderUrl || EMPTY_AVATAR_SRC}
        />
      </div>
      <div className="flex w-full min-w-0 items-center justify-between">
        <div className="flex min-w-0 flex-col gap-1">
          <span className="typography-product-element-label capitalize">
            {player?.followerProfile?.fullName}
          </span>
          {isUsernameValid && (
            <span className="typography-product-text-card-mobile text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              {'@' + player?.followerProfile?.username}
            </span>
          )}
        </div>

        {player?.pendingAction?.coachLessonType === RemoteCoachTypesEnum.Remote && (
          <ButtonLink
            href={getVideoAnalysisPageUrl(player.pendingAction.lessonId)}
            variant="brand"
            iconLeft={<VideoCamera className="h-4 w-4 " />}
            size="sm"
            className="w-fit whitespace-nowrap"
          >
            Watch video
          </ButtonLink>
        )}

        {player?.pendingAction?.coachLessonType === RemoteCoachTypesEnum.Court && (
          <Button
            variant="brand"
            iconLeft={<NoteIcon className="h-4 w-4" />}
            size="sm"
            className="w-fit whitespace-nowrap"
            onClick={(e) => {
              handlePlayerSelect?.(player, 'reflection');
            }}
          >
            Reflect
          </Button>
        )}
      </div>
    </div>
  );
};

export default PlayerCard;
