import React, { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import SearchIcon from 'svg/SearchIcon';
import { PlayerFiltersFormValues } from 'screens/CoachPlayersTab/props';

const SearchInput: React.FC<{ control: Control<PlayerFiltersFormValues> }> = ({ control }) => {
  const [searchString, setSearchString] = useState('');

  const handleDebouncedChange = useMemo(
    () =>
      debounce((val, onChange) => {
        onChange(val);
      }, 300),
    [],
  );

  return (
    <div className="relative mt-6">
      <SearchIcon className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 text-brand-gray-500" />
      <Controller
        control={control}
        name={'playerSearchText'}
        render={({ field }) => (
          <input
            {...field}
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
              handleDebouncedChange(e.target.value, field.onChange);
            }}
            placeholder="Search player"
            className="typography-product-body w-full rounded-md border-0 bg-color-bg-input-lightmode-primary pl-8 dark:bg-color-bg-input-darkmode-primary"
          />
        )}
      />
    </div>
  );
};

export default SearchInput;
