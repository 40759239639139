import { SelectPlayersSectionProps } from 'screens/CoachPlayersTab/props';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import classNames from 'styles/utils/classNames';
import Header from './Header';
import PlayerCards from './PlayerCards';
import PlayerCardsSkeleton from './PlayerCardsSkeleton';
import SearchInput from './SearchInput';

const SelectPlayersSection: React.FC<SelectPlayersSectionProps> = ({
  openFilterModal,
  players,
  control,
  handlePlayerSelect,
  handleSelectAll,
  selectedPlayerForDetail,
  loading,
  called,
  isVisibleOnMobile,
  handleInvitePlayers,
}) => {
  return (
    <div
      className={classNames(
        'sticky top-[theme(height.topnav)] flex  h-[calc(100dvh_-_theme(height.tabs))] min-h-0 w-full min-w-[25rem] shrink-0 flex-col overflow-y-auto border-r pt-0  md:h-[calc(100dvh_-_theme(height.topnav))] md:max-w-[30%]',
        !isVisibleOnMobile && 'max-md:hidden',
      )}
    >
      <div className="sticky top-0 z-10 bg-color-bg-lightmode-primary p-ds-xl dark:bg-color-bg-darkmode-primary">
        <Header
          openFilterModal={openFilterModal}
          playersCount={players.length}
          handleInvitePlayers={handleInvitePlayers}
        />
        <SearchInput control={control} />
      </div>

      {loading || !called ? (
        <PlayerCardsSkeleton />
      ) : players.length > 0 ? (
        <PlayerCards
          selectedPlayerForDetail={selectedPlayerForDetail}
          handlePlayerSelect={handlePlayerSelect}
          handleSelectAll={handleSelectAll}
          players={players}
        />
      ) : (
        <NoDataPlaceholder text="No players available" className="mx-ds-xl" />
      )}
    </div>
  );
};

export default SelectPlayersSection;
