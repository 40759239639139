const ClipboardListIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path fill="currentColor" d="M7.2 2.1a.8.8 0 1 0 0 1.6h1.6a.8.8 0 1 0 0-1.6H7.2Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.2 4.5a1.6 1.6 0 0 1 1.6-1.6 2.4 2.4 0 0 0 2.4 2.4h1.6a2.4 2.4 0 0 0 2.4-2.4 1.6 1.6 0 0 1 1.6 1.6v8.8a1.6 1.6 0 0 1-1.6 1.6H4.8a1.6 1.6 0 0 1-1.6-1.6V4.5Zm2.4 3.2a.8.8 0 1 0 0 1.6h.008a.8.8 0 1 0 0-1.6H5.6Zm2.4 0a.8.8 0 1 0 0 1.6h2.4a.8.8 0 0 0 0-1.6H8Zm-2.4 3.2a.8.8 0 0 0 0 1.6h.008a.8.8 0 0 0 0-1.6H5.6Zm2.4 0a.8.8 0 0 0 0 1.6h2.4a.8.8 0 0 0 0-1.6H8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ClipboardListIcon;
