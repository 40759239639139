import { memo } from 'react';
import { PlayerCardsProps } from 'screens/CoachPlayersTab/props';
import PlayerCard from './PlayerCard';

const PlayerCardMemoised = memo(PlayerCard);

const PlayerCards: React.FC<PlayerCardsProps> = ({
  players,
  selectedPlayerForDetail,
  handlePlayerSelect,
  handleSelectAll,
}) => {
  // Filter players with and without pendingVideoAnalysisId
  const playersWithPendingActions = players.filter((player) => player.pendingAction);
  const playersWithoutPendingActions = players.filter((player) => !player.pendingAction);

  return (
    <div className="mt-1 flex h-full min-h-0 flex-col gap-6 px-ds-xl pb-ds-xl">
      <PlayerCard
        selected={selectedPlayerForDetail === null}
        all
        handleSelectAll={handleSelectAll}
      />

      {/* Render players with pendingVideoAnalysisId */}

      {playersWithPendingActions.map((player, index) => (
        <PlayerCardMemoised
          key={`with-action-${index}`}
          player={player}
          selected={selectedPlayerForDetail?.followerProfile?.id === player?.followerProfile?.id}
          handlePlayerSelect={handlePlayerSelect}
        />
      ))}

      {/* Divider */}
      {playersWithPendingActions.length > 0 && playersWithoutPendingActions.length > 0 && <hr />}

      {/* Render players without pendingVideoAnalysisId */}
      {playersWithoutPendingActions.map((player, index) => (
        <PlayerCardMemoised
          key={`without-action-${index}`}
          player={player}
          selected={selectedPlayerForDetail?.followerProfile?.id === player?.followerProfile?.id}
          handlePlayerSelect={handlePlayerSelect}
        />
      ))}
    </div>
  );
};

export default PlayerCards;
