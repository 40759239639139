import React, { useCallback, useRef, useState } from 'react';
import { MicrophoneIcon, StopIcon } from '@heroicons/react/24/solid';
import { captureException } from '@sentry/nextjs';
import { buttonVariants } from 'components/Button/Button';

interface RecordVoiceProps {
  onRecordingComplete: (blob: Blob, fileType: string) => void;
  isUploading: boolean;
  showPreview?: boolean;
}

export default function RecordAudio({
  onRecordingComplete,
  isUploading,
  showPreview = true,
}: RecordVoiceProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isDisabled = isUploading;
  const [error, setError] = useState<string | null>(null);

  const selectAudioFormat = useCallback(() => {
    const formats = [
      { mimeType: 'audio/mpeg', description: 'MP3', fileType: 'mp3' },
      { mimeType: 'audio/wav', description: 'WAV (uncompressed PCM)', fileType: 'wav' },
      { mimeType: 'audio/mp4', description: 'MP4 with AAC codec', fileType: 'mp4' },
      { mimeType: 'audio/webm;codecs=opus', description: 'WebM with Opus codec', fileType: 'webm' },
      { mimeType: 'audio/ogg;codecs=opus', description: 'Ogg with Opus codec', fileType: 'ogg' },
    ];

    for (let format of formats) {
      if (MediaRecorder.isTypeSupported(format.mimeType)) {
        console.log(`Selected format: ${format.description}`);
        return format;
      }
    }

    captureException(new Error('No common formats supported by MediaRecorder.'));

    return null;
  }, []);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const format = selectAudioFormat();

      if (!format?.mimeType) {
        throw new Error('No supported audio format found.');
      }

      const mediaRecorder = new MediaRecorder(stream, { mimeType: format.mimeType });
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: format.mimeType });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        onRecordingComplete(blob, format.fileType);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setError(null); // Clear any previous errors
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
      setError('Microphone access is required to record audio. Please allow access and try again.');
    }
  }, [onRecordingComplete, selectAudioFormat]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setRecordingTime(0);

      // Stop all tracks in the stream to release the microphone
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      }

      // Clear the MediaRecorder reference
      mediaRecorderRef.current = null;
    }
  }, []);

  const playRecording = useCallback(() => {
    if (audioRef.current && audioUrl) {
      audioRef.current.play();
    }
  }, [audioUrl]);

  return (
    <div className="w-full">
      {error && (
        <div className="mb-2 text-pretty text-xs font-medium text-color-error">{error}</div>
      )}
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className="relative flex w-full items-center overflow-hidden rounded-lg border border-color-border-input-lightmode px-2 py-2 dark:border-color-border-input-darkmode"
        type="button"
        disabled={isDisabled}
      >
        {isRecording && (
          <div className="absolute inset-0 animate-recording-background bg-gradient-to-r from-transparent via-brand-fire-200 to-transparent dark:via-gray-700" />
        )}
        <span
          className={buttonVariants({
            variant: isRecording ? 'primary' : 'brand',
            size: 'icon',
            className: 'relative z-10 mr-2 w-auto p-2.5',
            inline: true,
            inlineDesktop: true,
          })}
        >
          {isRecording ? <StopIcon className="h-4 w-4" /> : <MicrophoneIcon className="h-4 w-4" />}
        </span>
        <div className="relative z-10 text-sm font-semibold">
          {!isRecording && <span>Record voice note</span>}
          {isRecording && (
            <span>
              Recording:{' '}
              <span className="font-bold text-color-brand-primary">{recordingTime} seconds</span>
            </span>
          )}
        </div>
      </button>
      {showPreview && audioUrl && (
        <div className="mt-2">
          <audio ref={audioRef} src={audioUrl} controls className="w-full" />
        </div>
      )}
    </div>
  );
}
