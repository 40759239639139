import { FieldError } from 'react-hook-form';
import toast from 'react-hot-toast';
import { UploadProvider } from 'context/UploadVideoContext';
import Button from 'components/Button';
import classNames from 'styles/utils/classNames';
import CollapsibleSection from './CollapsibleSection';
import { Step2Props } from '../../props';
import SkillFeedback from '../SkillFeedback';

const Step2: React.FC<Step2Props> = ({
  visible,
  control,
  skillsFeedback,
  trigger,
  onChangeStep,
  clearErrors,
  setError,
  submitting,
  errors,
}) => {
  const handleNext = async () => {
    const validated = await trigger(['feedback']);
    validated ? onChangeStep(3) : toast.error('Please complete the required fields');
  };

  const handleBack = () => {
    onChangeStep(1);
  };

  return (
    <div className={classNames('hidden', visible && '!block')}>
      <div className={'px-8'}>
        <h2 className="typography-product-heading-desktop mt-8">Skills Reflection</h2>
        <UploadProvider>
          <div className="mt-5 flex flex-col gap-4">
            {skillsFeedback?.map((skillFeedback, index) => (
              <CollapsibleSection
                key={`${skillFeedback.skill}_${index}`}
                title={skillFeedback.skill || skillFeedback.customSkill || ''}
                error={(errors?.feedback?.[index] as FieldError) || null}
              >
                <SkillFeedback
                  control={control}
                  skillIndex={index}
                  clearErrors={clearErrors}
                  setError={setError}
                />
              </CollapsibleSection>
            ))}
          </div>
        </UploadProvider>
      </div>
      <div className="mt-12 flex gap-4 border-t px-8 py-4">
        <Button variant="secondary" size="lg" onClick={handleBack}>
          Back
        </Button>
        <Button variant="primary" size="lg" onClick={handleNext} disabled={submitting}>
          {submitting ? 'Submitting' : 'Finish'}
        </Button>
      </div>
    </div>
  );
};

export default Step2;
