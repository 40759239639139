import { Controller } from 'react-hook-form';
import CloseIcon from 'svg/CloseIcon';
import EraseIcon from 'svg/Erase';
import { Gender, LessonPreference } from 'screens/CoachPlayersTab/enums';
import { FiltersModalProps } from 'screens/CoachPlayersTab/props';
import Button, { ButtonText } from 'components/Button';
import FilterBox from 'components/forms/FilterBox/FilterBox';
import { SliderNumberRange } from 'components/forms/Slider';
import Modal from 'components/modals/Modal';

const FiltersModal: React.FC<FiltersModalProps> = ({ open, handleClose, form }) => {
  const { control, reset } = form;

  const handleClear = () => {
    reset();
  };

  return (
    <Modal isOpen={open} handleClose={handleClose} classNameMaxWidth="max-w-[40rem]">
      <div className="flex justify-between border-b px-8 py-5">
        <h2 className="typography-product-heading">Filters</h2>
        <ButtonText onClick={handleClose}>
          <CloseIcon className="h-4 w-4" />
        </ButtonText>
      </div>

      <form>
        <div className="px-8 py-6">
          {/* Client Type */}
          <div>
            <h3 className="typography-product-subheading mb-2">Client type</h3>
            <Controller
              name="clientType"
              control={control}
              render={({ field }) => (
                <div className="flex space-x-4">
                  <FilterBox
                    label="All"
                    onClick={() => field.onChange('All')}
                    isSelected={field.value === 'All'}
                  />
                  <FilterBox
                    label="On-court"
                    onClick={() => field.onChange('On-court')}
                    isSelected={field.value === 'On-court'}
                  />
                  <FilterBox
                    label="Remote"
                    onClick={() => field.onChange('Remote')}
                    isSelected={field.value === 'Remote'}
                  />
                </div>
              )}
            />
          </div>

          {/* Skill Level */}
          <div className="mt-6">
            <h3 className="typography-product-subheading mb-12">Skill level</h3>
            <Controller
              name="skillLevel"
              control={control}
              render={({ field }) => (
                <SliderNumberRange
                  rangeMinimum={0}
                  rangeMaximum={6}
                  valueMinimum={field.value.min}
                  valueMaximum={field.value.max}
                  setValueMinumum={(newMin) => field.onChange({ ...field.value, min: newMin })}
                  setValueMaximum={(newMax) => field.onChange({ ...field.value, max: newMax })}
                  step={0.25}
                  decimals={2}
                  isDisabled={false}
                />
              )}
            />
          </div>

          {/* Gender */}
          <div className="mt-6">
            <h3 className="typography-product-subheading mb-2">Gender</h3>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <div className="flex space-x-4">
                  <FilterBox
                    label="All"
                    onClick={() => field.onChange(Gender.All)}
                    isSelected={field.value === Gender.All}
                  />
                  <FilterBox
                    label="Men"
                    onClick={() => field.onChange(Gender.Men)}
                    isSelected={field.value === Gender.Men}
                  />
                  <FilterBox
                    label="Women"
                    onClick={() => field.onChange(Gender.Women)}
                    isSelected={field.value === Gender.Women}
                  />
                </div>
              )}
            />
          </div>

          {/* Lesson Preference */}
          <div className="mt-6">
            <h3 className="typography-product-subheading mb-2">Lesson preference</h3>
            <Controller
              name="lessonPreference"
              control={control}
              render={({ field }) => (
                <div className="flex space-x-4">
                  <FilterBox
                    label="Individual"
                    onClick={() => field.onChange(LessonPreference.Individual)}
                    isSelected={field.value === LessonPreference.Individual}
                  />
                  <FilterBox
                    label="Group"
                    onClick={() => field.onChange(LessonPreference.Group)}
                    isSelected={field.value === LessonPreference.Group}
                  />
                </div>
              )}
            />
          </div>

          {/* Age Range */}
          <div className="mt-6">
            <h3 className="typography-product-subheading mb-12">Age</h3>
            <Controller
              name="ageRange"
              control={control}
              render={({ field }) => (
                <SliderNumberRange
                  rangeMinimum={0}
                  rangeMaximum={99}
                  valueMinimum={field.value.min}
                  valueMaximum={field.value.max}
                  setValueMinumum={(newMin) => field.onChange({ ...field.value, min: newMin })}
                  setValueMaximum={(newMax) => field.onChange({ ...field.value, max: newMax })}
                  step={1}
                  decimals={0}
                  isDisabled={false}
                />
              )}
            />
          </div>
        </div>
        {/* Actions */}
        <div className="flex justify-between border-t px-8 py-5">
          <ButtonText onClick={handleClear} className="flex items-center gap-1">
            <EraseIcon className="mr-1 w-4" />
            Clear all
          </ButtonText>

          <Button className="w-fit" variant="primary" size="lg" onClick={handleClose}>
            Show Players
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default FiltersModal;
