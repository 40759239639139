import React from 'react';
import Clock from 'svg/Clock';
import CoachBadge from 'svg/CoachBadge';
import { OnCourtStatsProps } from 'screens/CoachPlayersTab/props';
import StatCard from 'components/SkillAnalysis/StatCard';

const Stats: React.FC<OnCourtStatsProps> = ({ lessonTakenCount, awaitingAnalysis }) => {
  return (
    <div className="flex gap-4">
      <StatCard
        num={lessonTakenCount}
        title="Lesson Taken"
        icon={<CoachBadge className="h-8 w-8 text-color-text-lightmode-tertiary" />}
      />
      <StatCard
        num={awaitingAnalysis}
        title="Awaiting Reflections"
        brand
        icon={<Clock className="h-8 w-8 text-color-brand-primary" />}
      />
    </div>
  );
};

export default Stats;
