import * as React from "react";

export default function FilterIcon({
  className,
  viewBox = "0 0 16 16",
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      className={className}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333H8.78047C9.05503 10.5565 9.79585 10 10.6667 10C11.5375 10 12.2783 10.5565 12.5529 11.3333H13.3333C13.7015 11.3333 14 11.6318 14 12C14 12.3682 13.7015 12.6667 13.3333 12.6667H12.5529C12.2783 13.4435 11.5375 14 10.6667 14C9.79585 14 9.05503 13.4435 8.78047 12.6667H2.66667C2.29848 12.6667 2 12.3682 2 12ZM2 8C2 7.63181 2.29848 7.33333 2.66667 7.33333H3.44714C3.72169 6.55654 4.46252 6 5.33333 6C6.20415 6 6.94497 6.55654 7.21953 7.33333L13.3333 7.33333C13.7015 7.33333 14 7.63181 14 8C14 8.36819 13.7015 8.66667 13.3333 8.66667H7.21953C6.94497 9.44346 6.20415 10 5.33333 10C4.46252 10 3.72169 9.44346 3.44714 8.66667H2.66667C2.29848 8.66667 2 8.36819 2 8ZM2 4C2 3.63181 2.29848 3.33333 2.66667 3.33333H8.78047C9.05503 2.55654 9.79585 2 10.6667 2C11.5375 2 12.2783 2.55654 12.5529 3.33333H13.3333C13.7015 3.33333 14 3.63181 14 4C14 4.36819 13.7015 4.66667 13.3333 4.66667H12.5529C12.2783 5.44346 11.5375 6 10.6667 6C9.79585 6 9.05503 5.44346 8.78047 4.66667H2.66667C2.29848 4.66667 2 4.36819 2 4ZM4.66667 8C4.66667 8.36819 4.96514 8.66667 5.33333 8.66667C5.70152 8.66667 6 8.36819 6 8C6 7.63181 5.70152 7.33333 5.33333 7.33333C4.96514 7.33333 4.66667 7.63181 4.66667 8ZM10 12C10 12.3682 10.2985 12.6667 10.6667 12.6667C11.0349 12.6667 11.3333 12.3682 11.3333 12C11.3333 11.6318 11.0349 11.3333 10.6667 11.3333C10.2985 11.3333 10 11.6318 10 12ZM10 4C10 4.36819 10.2985 4.66667 10.6667 4.66667C11.0349 4.66667 11.3333 4.36819 11.3333 4C11.3333 3.63181 11.0349 3.33333 10.6667 3.33333C10.2985 3.33333 10 3.63181 10 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
