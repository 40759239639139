import { useState } from 'react';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { Button } from 'components/Button/Button';
import Modal from 'components/modals/Modal';

export interface PlayerRow {
  id: string;
  fullName: string;
  email: string;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (players: PlayerRow[]) => Promise<void>;
  isLoading?: boolean;
}

export default function BulkAddPlayersModal({ isOpen, handleClose, onSubmit, isLoading }: Props) {
  const [players, setPlayers] = useState<PlayerRow[]>([
    {
      id: '1',
      fullName: '',
      email: '',
    },
  ]);

  const handleSubmit = async () => {
    await onSubmit(players);
    setPlayers([{ id: '1', fullName: '', email: '' }]);
    handleClose();
  };

  const addNewPlayer = () => {
    setPlayers([
      ...players,
      {
        id: (players.length + 1).toString(),
        fullName: '',
        email: '',
      },
    ]);
  };

  const updatePlayer = (id: string, field: keyof PlayerRow, value: string) => {
    setPlayers(players.map((p) => (p.id === id ? { ...p, [field]: value } : p)));
  };

  const removePlayer = (id: string) => {
    if (players.length > 1) {
      setPlayers(players.filter((p) => p.id !== id));
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="relative p-6">
        <h2 className="mb-4 text-xl font-semibold">Add Clients</h2>
        <p className="mb-4 text-pretty text-sm text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          We will send an email with a link to join. You are still able to fill out reflections and
          treat them like a normal client before they join.
        </p>

        <div className="space-y-4">
          {players.map((player) => (
            <div key={player.id} className="space-y-2">
              <input
                className="input-form w-full"
                value={player.fullName}
                onChange={(e) => updatePlayer(player.id, 'fullName', e.target.value)}
                placeholder="Full Name"
                type="text"
              />
              <div className="flex gap-2">
                <input
                  className="input-form w-full"
                  value={player.email}
                  onChange={(e) => updatePlayer(player.id, 'email', e.target.value)}
                  placeholder="Enter email"
                  type="email"
                />
                {players.length > 1 && (
                  <button
                    onClick={() => removePlayer(player.id)}
                    className="rounded-full p-2 text-gray-500 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={addNewPlayer}
          className="mt-4 flex w-full items-center justify-center gap-2 rounded-md border border-gray-300 p-2 text-sm text-gray-600 hover:bg-gray-50"
        >
          <PlusIcon className="h-4 w-4" />
          Add Another Player
        </button>

        <div className="mt-4">
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            variant="primary"
            size="lg"
            className="w-full"
          >
            {isLoading ? 'Loading...' : 'Add'}
          </Button>
        </div>

        <button
          type="button"
          onClick={handleClose}
          className="absolute right-2 top-2 rounded-full p-2 transition-colors hover:bg-color-bg-lightmode-secondary dark:hover:bg-color-bg-darkmode-secondary"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </Modal>
  );
}
