import { useMemo } from 'react';
import Image from 'next/image';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import { isUuidFormatValid } from 'utils/shared/string/isUuidFormatValid';
import { PlayerHeaderProps } from 'screens/CoachPlayersTab/props';

const PlayerHeader: React.FC<PlayerHeaderProps> = ({ selectedPlayerForDetail }) => {
  const isUsernameValid = useMemo(
    () => isUuidFormatValid(selectedPlayerForDetail?.followerProfile?.username || '') === false,
    [selectedPlayerForDetail?.followerProfile?.username],
  );

  return (
    <div className="mb-6 flex justify-between sm:mt-4">
      <div className="flex items-center gap-2">
        {selectedPlayerForDetail && (
          <div className="relative h-14 w-14 overflow-hidden rounded-full max-sm:h-12 max-sm:w-12">
            <Image
              alt="profile-avatar"
              fill
              src={
                selectedPlayerForDetail?.followerProfile?.profileImageProviderUrl ||
                EMPTY_AVATAR_SRC
              }
            />
          </div>
        )}
        <div className="flex flex-col gap-1">
          <h2 className="typography-product-heading-compact-desktop capitalize">
            {selectedPlayerForDetail === null
              ? 'All Clients'
              : selectedPlayerForDetail?.followerProfile?.fullName}
          </h2>
          {isUsernameValid && (
            <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              {selectedPlayerForDetail?.followerProfile?.username}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerHeader;
