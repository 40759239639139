import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Button from 'components/Button';
import AddSkillModal from 'components/VideoAnalysis/AddSkillModal';
import Skills from 'components/VideoAnalysis/Skills';
import classNames from 'styles/utils/classNames';
import NumberedDropdown from '../../NumberedDropdown';
import { Step1Props } from '../../props';

const Step1: React.FC<Step1Props> = ({
  visible,
  control,
  getValues,
  setValue,
  selectedSkills,
  trigger,
  onChangeStep,
}) => {
  const [isAddSkillModalOpen, setIsAddSkillModalOpen] = useState(false);

  const handleNext = async () => {
    const validated = await trigger(['skills', 'skillsRanking'] as const);
    validated && onChangeStep(2);
  };

  return (
    <div className={classNames('hidden', visible && '!block')}>
      <div className={'px-8'}>
        <h2 className="typography-product-heading-desktop">Skills Trained</h2>
        <div className="flex flex-col gap-4">
          <Skills
            control={control}
            label={false}
            allowCustom={true}
            getValues={getValues}
            setValue={setValue}
          />
        </div>

        <h2 className="typography-product-heading-desktop mt-10">
          Rank the skills by time spent, most to least
        </h2>
        <Controller
          name="skillsRanking"
          control={control}
          render={({ field: { onChange, value: skillsRanking = [] }, fieldState: { error } }) => (
            <div className="mt-6">
              <div className="flex flex-col gap-4">
                {selectedSkills.map((skill, index) => (
                  <NumberedDropdown
                    key={skill}
                    num={index + 1}
                    options={selectedSkills}
                    selectedValue={skillsRanking[index] || ''}
                    onChange={(val) => {
                      const updatedRanking = [...skillsRanking];
                      updatedRanking[index] = val;
                      onChange(updatedRanking);
                    }}
                  />
                ))}
              </div>
              {error?.message && <p className="mt-2 text-xs text-red-600">{error.message}</p>}
            </div>
          )}
        />
      </div>
      <div className="mt-12 flex justify-end gap-4 border-t px-8 py-4">
        <Button variant="primary" size="lg" className="w-fit" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Step1;
