import React from 'react';
import { pluralize } from 'utils/shared/pluralize';
import ClipboardListIcon from 'svg/ClipboardListIcon';
import { DetailBarProps } from 'screens/CoachPlayersTab/props';
import { ButtonText } from 'components/Button';

const DetailBar: React.FC<DetailBarProps> = ({ handleBack, totalPlayers }) => {
  return (
    <div className="sticky top-0 z-[11] flex h-16 items-center justify-between border-b bg-color-bg-lightmode-primary px-4 dark:bg-color-bg-darkmode-primary md:hidden">
      <span className="typography-product-card-title-mobile">
        {totalPlayers}{' '}
        {pluralize({
          singular: 'Client',
          plural: 'Clients',
          count: totalPlayers,
          hideCount: true,
        })}
      </span>
      <ButtonText
        className="typography-product-button-label-medium flex items-center gap-1"
        onClick={handleBack}
      >
        <ClipboardListIcon className="mb-0.5 h-4 w-4 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary" />
        <span>View List</span>
      </ButtonText>
    </div>
  );
};

export default DetailBar;
