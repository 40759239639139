import { useEffect, useState } from 'react';
import ChevronUp from 'svg/ChevronUp';
import { ButtonText } from 'components/Button';
import Pill from 'components/Pill';
import { getSkillLabel } from 'components/SkillAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { CollapsibleSectionProps } from '../../props';

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, children, error }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (error) {
      setIsExpanded(true);
    }
  }, [error]);

  return (
    <div className="border-b border-gray-200 pb-4 last:border-b-0">
      <div className="flex items-center justify-between">
        {<Pill type="brand" text={getSkillLabel(title)} />}
        <ButtonText onClick={toggleExpand} className="flex items-center gap-1">
          <span>{isExpanded ? 'Show less' : 'Show more'}</span>
          <ChevronUp className={classNames('h-4 w-4', isExpanded ? 'rotate-180' : 'rotate-0')} />
        </ButtonText>
      </div>

      <div className={classNames('mt-4 hidden', isExpanded && '!block border-t')}>{children}</div>
    </div>
  );
};

export default CollapsibleSection;
