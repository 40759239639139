import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  FeedbackSentimentEnum,
  FileCategoryEnum,
  FileFormatEnum,
  useInsertVideoAnalysisSubmissionLogMutation,
  useUpdateVideoAnalysisSubmissionLogMutation,
} from 'types/generated/client';
import { useUploadVideoFile } from 'hooks/useUploadVideoFile';
import { useViewer } from 'hooks/useViewer';
import FeedbackOption from 'components/VideoAnalysis/FeedbackOption';
import FileUploader from 'components/VideoAnalysis/FileUploader';
import RecordAudio from 'components/VideoAnalysis/RecordAudio';
import RecordVideo from 'components/VideoAnalysis/RecordVideo';
import { isVideoDurationValid } from 'components/VideoAnalysis/utils';
import { MediaFieldNameType, SkillFeedbackProps } from '../props';

const SkillFeedback: React.FC<SkillFeedbackProps> = ({
  control,
  skillIndex,
  setError,
  clearErrors,
}) => {
  const { uploadVideoFile, uploadProgress } = useUploadVideoFile();
  const viewer = useViewer();
  const [insertVideoAnalysisSubmissionLogMutation] = useInsertVideoAnalysisSubmissionLogMutation();
  const [updateVideoAnalysisSubmissionLogMutation] = useUpdateVideoAnalysisSubmissionLogMutation();
  const [selectedTabToUpload, setSelectedTabToUpload] = useState<number | null>(null);

  const uploading = uploadProgress !== null && uploadProgress < 100;

  const handleMediaUpload = async (files: File[], onChange: any, fieldName: MediaFieldNameType) => {
    try {
      const file = files[0];
      setSelectedTabToUpload(skillIndex);
      // Check video duration if it's a video file
      const isVideo = file.type.startsWith('video/');
      if (isVideo) {
        clearErrors(fieldName); // Clear any previous errors for this field
        const isValidDuration = await isVideoDurationValid(file, 900 * 4); // Custom max seconds (900s = 15 minutes)

        if (!isValidDuration) {
          setError(fieldName, {
            type: 'manual',
            message: 'Video exceeds 15 minutes. Please upload a shorter video.',
          });
          return;
        }
      }

      const [VideoAnalysisSubmissionLogMutationRes, fileUploadRes] = await Promise.all([
        insertVideoAnalysisSubmissionLogMutation({
          variables: {
            userId: viewer.userId,
            videoName: file.name,
          },
        }),
        uploadVideoFile({ file }),
      ]);

      if (!fileUploadRes) {
        throw new Error('Video upload failed.');
      }

      const fileLogId =
        VideoAnalysisSubmissionLogMutationRes.data?.insertVideoAnalysisSubmissionLogOne?.id;

      const fileFormat = fileUploadRes.fileType.startsWith('video/')
        ? FileFormatEnum.Video
        : fileUploadRes.fileType.startsWith('audio/')
        ? FileFormatEnum.Audio
        : FileFormatEnum.Image;

      const formattedFile = {
        fileCategory: FileCategoryEnum.Other,
        fileName: fileUploadRes.fileName,
        fileType: fileUploadRes.fileType,
        host: fileUploadRes.host,
        originalFileName: fileUploadRes.originalFileName,
        provider: fileUploadRes.provider,
        providerUrl: fileUploadRes.providerUrl,
        fileFormat: fileFormat,
        url: fileUploadRes.url,
        providerId: '',
        userId: viewer.userId,
      };

      onChange(formattedFile);

      if (fileLogId) {
        await updateVideoAnalysisSubmissionLogMutation({
          variables: {
            id: fileLogId,
            userId: viewer.userId,
            videoPath: fileUploadRes.path,
            videoName: fileUploadRes.fileName,
          },
        });
      }
    } catch (error) {
      console.error('Media upload failed:', error);
      setError(fieldName, {
        type: 'manual',
        message: 'Error uploading media. Please try again.',
      });
    } finally {
      setSelectedTabToUpload(null);
    }
  };

  const handleVoiceRecording = async (
    blob: Blob,
    fileType: string,
    onChange: any,
    fieldName: MediaFieldNameType,
  ) => {
    try {
      clearErrors(fieldName);
      const file = new File([blob], `voice_recording.${fileType}`, { type: blob.type });
      handleMediaUpload([file], onChange, fieldName);
    } catch (error) {
      console.error('Voice recording upload failed:', error);
      setError(fieldName, {
        type: 'manual',
        message: 'Error uploading voice recording. Please try again.',
      });
    }
  };

  const handleVideoRecording = async (
    blob: Blob,
    fileType: string,
    onChange: any,
    fieldName: MediaFieldNameType,
  ) => {
    try {
      clearErrors(fieldName);
      const file = new File([blob], `video_recording.${fileType}`, { type: blob.type });
      handleMediaUpload([file], onChange, fieldName);
    } catch (error) {
      console.error('Video recording upload failed:', error);
      setError(fieldName, {
        type: 'manual',
        message: 'Error uploading video recording. Please try again.',
      });
    }
  };

  return (
    <div className="mt-4">
      {/* Feedback Type Options */}
      <Controller
        name={`feedback.${skillIndex}.sentiment`}
        control={control}
        render={({ field }) => (
          <>
            <span className="typography-product-subheading">Feedback type</span>
            <div className="mt-3 flex gap-2">
              {Object.entries(FeedbackSentimentEnum)
                .reverse()
                .map(([label, type]) => (
                  <FeedbackOption
                    key={type}
                    title={label}
                    type={type}
                    selected={field.value === type}
                    onClick={field.onChange}
                  />
                ))}
            </div>

            {/* Detailed Feedback */}
            <div className="mt-4">
              <Controller
                name={`feedback.${skillIndex}.details`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <textarea
                      {...field}
                      className="mt-3 h-[80px] w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
                      placeholder="Provide detailed feedback"
                    />
                    {error && <p className="text-sm text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            {/* Media Upload Section */}
            <div className="mt-4">
              <span className="typography-product-subheading">Add Media</span>

              {/* Record Audio */}

              <Controller
                name={`feedback.${skillIndex}.lessonReflectionFiles`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="mt-2 grid grid-cols-2 gap-4">
                    <div>
                      <RecordAudio
                        showPreview={false}
                        isUploading={uploading}
                        onRecordingComplete={(blob: Blob, fileType: string) =>
                          handleVoiceRecording(
                            blob,
                            fileType,
                            field.onChange,
                            `feedback.${skillIndex}.lessonReflectionFiles`,
                          )
                        }
                      />
                    </div>
                    <div>
                      <RecordVideo
                        showPreview={false}
                        isUploading={uploading}
                        onRecordingComplete={(blob: Blob, fileType: string) =>
                          handleVideoRecording(
                            blob,
                            fileType,
                            field.onChange,
                            `feedback.${skillIndex}.lessonReflectionFiles`,
                          )
                        }
                      />
                    </div>
                    <div className="col-span-2">
                      <FileUploader
                        multiple={false}
                        uploadProgress={selectedTabToUpload === skillIndex ? uploadProgress : null}
                        selectedFiles={field.value ? [field.value] : []}
                        onRemoveFile={() => field.onChange(null)}
                        onSelectFiles={(files) =>
                          handleMediaUpload(
                            files,
                            field.onChange,
                            `feedback.${skillIndex}.lessonReflectionFiles`,
                          )
                        }
                        helperText="PNG, JPG, or MP4 (max. 20 MB image or 60 minute video)"
                        accept={{
                          'image/*': [],
                          'video/*': ['.mp4', '.mov'],
                          'audio/*': ['.mp3', '.mp4', '.wav', '.m4a', '.ogg', '.webm'],
                        }}
                      />
                    </div>
                    {error && <p className="col-span-2 text-sm text-red-600 ">{error.message}</p>}
                  </div>
                )}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default SkillFeedback;
