import React from 'react';
import Clock from 'svg/Clock';
import VideoCamera from 'svg/VideoCamera';
import { RemoteStatsProps } from 'screens/CoachPlayersTab/props';
import StatCard from 'components/SkillAnalysis/StatCard';

const Stats: React.FC<RemoteStatsProps> = ({ videosCount, pendingCount }) => {
  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      <StatCard
        num={videosCount}
        title="Remote lessons taken"
        icon={<VideoCamera className="h-8 w-8 text-color-text-lightmode-tertiary" />}
      />
      <StatCard
        num={pendingCount}
        title="Awaiting review"
        brand
        icon={<Clock className="h-8 w-8 text-color-brand-primary" />}
      />
    </div>
  );
};

export default Stats;
