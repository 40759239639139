export enum ClientType {
  All = 'All',
  OnCourt = 'On-court',
  Remote = 'Remote',
}

export enum Gender {
  All = 'All',
  Men = 'Men',
  Women = 'Women',
}

export enum LessonPreference {
  Individual = 'Individual',
  Group = 'Group',
}

export enum MobileCoachPlayerTabScreenEnum {
  List = 'list',
  Detail = 'Detail',
}
