import Skeleton from 'components/LoadingSkeleton';

const PlayerCardsSkeleton = () => {
  return (
    <div className="space-y-4 px-ds-xl pb-ds-xl">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="flex items-center gap-2 rounded-lg bg-color-bg-lightmode-primary p-ds-sm dark:bg-color-bg-darkmode-primary"
        >
          <div className="relative h-14 w-14 shrink-0 overflow-hidden rounded-full">
            <Skeleton circle height={56} width={56} />
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col gap-1">
              <Skeleton width={100} height={16} />
              <Skeleton width={80} height={12} />
            </div>
            <div className="flex gap-4">
              <Skeleton width={60} height={32} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerCardsSkeleton;
