import { useMemo, useState } from 'react';
import { AccountType } from 'constants/user';
import { LessonPlayerSkillAssessments, LessonReflections } from 'types/generated/client';
import { OnCourtDetailsProps } from 'screens/CoachPlayersTab/props';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import ConfidenceSwitcher from 'components/SkillAnalysis/ConfidenceSwticher';
import ConfidentCard from 'components/SkillAnalysis/ConfidentCard';
import OnCourtFeedbackTable from 'components/SkillAnalysis/OnCourtFeedbackTable';
import ReflectionSection from 'components/SkillAnalysis/ReflectionSection';
import SkillsTrain from 'components/SkillAnalysis/SkillsTrain';
import { ConfidenceTabs } from 'components/SkillAnalysis/enums';
import {
  getFutureSkills,
  processLessonDataConfidence,
  processLessonReflections,
} from 'components/SkillAnalysis/utils';
import classNames from 'styles/utils/classNames';
import Stats from './Stats';

const OnCourtDetails: React.FC<OnCourtDetailsProps> = ({
  visible,
  data,
  handleReflectOnPlayer,
  handleTriggerEditReflection,
}) => {
  const [activeTab, setActiveTab] = useState<ConfidenceTabs>(ConfidenceTabs.Recent);

  const lessonTakenCount = data?.lessons.length || 0;

  const processedReflections = useMemo(
    () => (data?.lessons ? processLessonReflections(data.lessons) : []),
    [data?.lessons],
  );

  const awaitingAnalysis = useMemo(
    () => processedReflections.filter((reflection) => reflection.isCoachReflectionPending).length,
    [processedReflections],
  );

  // const timeSpentString = useMemo(() => getTimeSpentOnCourt(data?.lessons), [data?.lessons]);

  const confidence = useMemo(
    () =>
      data?.lessonPlayerSkillAssessments
        ? processLessonDataConfidence(
            data.lessonPlayerSkillAssessments as LessonPlayerSkillAssessments[],
          )
        : null,
    [data?.lessonPlayerSkillAssessments],
  );

  const futureSkills = useMemo(
    () =>
      data?.lessonPlayerSkillAssessments
        ? getFutureSkills(data.lessonPlayerSkillAssessments as LessonPlayerSkillAssessments[])
        : [],
    [data?.lessonPlayerSkillAssessments],
  );

  const lessonReflections = useMemo(
    () =>
      data?.lessons
        .map((lesson) => lesson.lessonReflections?.[0])
        .filter((reflection) => !!reflection) || [],
    [data?.lessons],
  );

  const confidenceSkillsMapper = {
    [ConfidenceTabs.General]: confidence?.general,
    [ConfidenceTabs.Recent]: confidence?.mostRecent,
  };

  const noConfidenceAvailable =
    confidenceSkillsMapper[activeTab]?.positive!?.length === 0 &&
    confidenceSkillsMapper[activeTab]?.positive!?.length === 0;

  return (
    <div className={classNames('mt-10 hidden', visible && '!block')}>
      <div className="flex flex-col">
        <Stats lessonTakenCount={lessonTakenCount} awaitingAnalysis={awaitingAnalysis} />
        {lessonReflections!?.length > 0 && (
          <OnCourtFeedbackTable
            className="mt-11"
            lessonReflections={lessonReflections as LessonReflections[]}
          />
        )}
        {noConfidenceAvailable === false && (
          <>
            <h2 className="typography-product-heading-compact-desktop mb-4 mt-11 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              Player’s confidence
            </h2>
            <ConfidenceSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
          </>
        )}
        {confidenceSkillsMapper[activeTab]?.positive!?.length > 0 &&
          confidenceSkillsMapper[activeTab]?.positive!?.length > 0 && (
            <div className="mt-4 grid grid-cols-2 gap-3">
              <ConfidentCard confident skills={confidenceSkillsMapper[activeTab]?.positive || []} />
              <ConfidentCard skills={confidenceSkillsMapper[activeTab]?.negative || []} />
            </div>
          )}
        {futureSkills.length > 0 && <SkillsTrain className="mt-10" futureSkills={futureSkills} />}

        {processedReflections.length > 0 ? (
          <>
            <ReflectionSection
              userType={AccountType.Coach}
              onReflect={handleReflectOnPlayer}
              onEditReflection={handleTriggerEditReflection}
              reflections={processedReflections}
              table
            />
          </>
        ) : (
          <NoDataPlaceholder text="No lessons" className="mt-11" />
        )}
      </div>
    </div>
  );
};

export default OnCourtDetails;
