export interface PostRequestPayload {
  email: string;
  fullName?: string;
}

export interface PostResponsePayload {
  userId: string;
  email: string;
  fullName: string;
}

export const API_ENDPOINT = '/v1/lessons/invite';
